.no-border {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.scrollbox {
  height:200px;
  overflow-x:scroll;
      height: 150px;
    border: groove;
    padding: 10px;
    overflow-x: scroll;
}


/*-----Base-----*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url('/css/vendor/boxsizing.htc');
}
html {
  min-height: 100%;
  position: relative;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: #ffffff;
  font-size: 13px;
}
body.gray {
  background: #eaeaea;
}
p.sub {
  color: #4f4f4f;
}
p.sub-minor {
  color: #696969;
  font-size: 12.75px;
  font-style: italic;
  line-height: 20px;
}
/* Indentation for Chinese Language Releases. */
p.indent {
	text-indent: 2em;
}
img {
  max-width: none;
}
img.push {
  margin: 13px 0 20px;
}
img.contain {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
img.img-circle {
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
::-moz-selection {
  background: #dddddd;
}
::selection {
  background: #dddddd;
}
/*-----Typography-----*/
h1 {
  font-size: 34px;
  font-weight: 600;
}
h1.page_title {
  font-weight: 300;
  color: #333333;
  margin: 25px 0;
  letter-spacing: -0.34px;
}
h1.page_title.red {
  color: #dc0002;
}
/* Replaces h1.page_title small, which is messing up Google results 4/11/16 */
div.release_date {
  display: block;
  text-transform: uppercase;
  color: #333333;
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: normal;
  margin-top: 23px;
  margin-bottom: -18px;
}
div.release_date .red {
  color: #dc0002;
}
div.release_date .lite {
  color: #333333;
  font-weight: 400;
}
h2 {
  font-weight: 500;
}
h2.post_title {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 0;
}
h2.news_title {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 10px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
h2.subhead {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #dc0002;
  text-transform: uppercase;
  margin: 30px 0;
  padding: 0;
}
h2.subhead.secondary {
  color: #005080;
  border: none;
  border-left: 2px solid #333333;
  padding-left: 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
h2.subhead.secondary.marine {
  border-left: 2px solid #333333;
  color: #008875;
}
h2.subhead.secondary.cancer {
  border-left: 2px solid #333333;
  color: #7f222a;
}
h2.subhead.text-left {
  text-align: left;
}
h3.subject_title {
  color: #333333;
  font-size: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid;
  padding-bottom: 8px;
  margin-top: 20px;
}
a {
  color: #0088cc;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #08a3f1;
}
a:focus,
a:active,
a:visited {
  text-decoration: none;
}
a.inverse {
  color: #000000;
}
a.inverse:hover {
  color: #08a3f1;
}
a.push {
  top: 30px;
  position: relative;
}
b,
strong {
  font-weight: 600;
}
hr.major {
  height: 8px;
  background: #f1f1f1;
  margin: 0 -20px;
  border-bottom: 1px solid #b9babc;
  border-top: 1px solid #b9babc;
}
hr.minor {
  height: 1px;
  background: #b9babc;
  margin: 0 -20px;
  border: none;
}
hr.trending {
  height: 1px;
  background: #b9babc;
  margin: 20px -20px 20px -20px;
  border: none;
}
.well {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  background: #f1f1f1;
}
.panel-body {
  padding: 20px;
}
.lightgraypanel {
  background-color: #e7e7e7;
}
.arabic {
  font-family: Traditional Arabic;
  direction: rtl;
}
.fa-eurekalert {
  vertical-align: middle;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.fa-eurekalert:before {
  content: '';
  display: block;
  background: url('/images/icn.eurekalert.png');
  width: 13px;
  height: 13px;
  -webkit-background-size: 13px 13px;
  -moz-background-size: 13px 13px;
  -o-background-size: 13px 13px;
  background-size: 13px 13px;
}
.break-text {
  white-space: pre;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.no-break-text {
  white-space: nowrap;
}
.category {
color: #17a2b8;
}
/*-----Scaffolding-----*/
#main-content,
#sidebar-content {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}

.equal-height {
  overflow: hidden;
}
.equal-height > * {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.full-height {
  height: 100%;
}
.pipe > *:before {
  display: block;
  position: absolute;
  content: "";
  height: 100%;
  left: 0;
  border-left: 1px solid #d9d9d9;
}
.pipe > *:first-child:before,
.pipe > *.hide-pipe:before {
  border-left: none;
  display: none;
  visibility: hidden;
}
.center-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
}
.center-image:after {
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
  content: "";
}
.center-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
/*-----Buttons-----*/
.btn {
  white-space: normal;
}
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-icon {
  padding-right: 25px !important;
  line-height: 18px !important;
}
.btn-icon i.fa.pull-right {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
/*-----Pagination-----*/
.pagination {
  margin: 0;
}
.pagination > li a {
  border-radius: 4px;
  margin: 0 4px;
  border-color: #0088cc;
  color: #0088cc;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.pagination > li a:hover {
  border-color: #0088cc;
  color: #0088cc;
  background: #cdeaf8;
}
.pagination > li.active a {
  color: #ffffff;
  background: #0088cc;
  border-color: #0088cc;
}
.pagination > li.active a:hover {
  background: #0088cc;
  border-color: #0088cc;
}
/*-----Navigation-----*/
.navbar {
  z-index: 980 !important;
  border: none;
  background: #cb2121 url('/images/bg.header-mobile.webp') center center no-repeat;
  margin-bottom: 0;
  min-height: 40px;
}
.navbar .container.flush {
  padding: 0;
}
.navbar .container.flush > .clearfix {
  position: relative;
}
.navbar.navbar-inverse {
  background: #505050;
}
.navbar.navbar-inverse .nav-pills {
  margin: 0 -20px;
}
.navbar.navbar-inverse .nav-pills > li {
  margin: 0;
}
.navbar.navbar-inverse .nav-pills > li > a {
  padding: 11px 18px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  border-radius: 0;
}
.navbar.navbar-inverse .nav-pills > li > a:hover,
.navbar.navbar-inverse .nav-pills > li > a:visited,
.navbar.navbar-inverse .nav-pills > li > a:active,
.navbar.navbar-inverse .nav-pills > li > a:focus {
  color: #ffffff;
  background: none;
}
.logo {
  display: inline-block;
  z-index: 1;
}
.logo img {
  display: inline-block;
  margin: 17px 0 16px;
  border-right: 1px solid #ffffff;
  width: auto;
  height: auto;
  max-width: 270px;
  max-height: 100%;
}
.brand {
  display: inline-block;
}
.brand img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 111px;
  max-height: 60px;
}
#search {
  text-align: right;
}
#search.navbar-collapse {
  border: none;
  box-shadow: none;
}
#search form {
  margin: 34px -15px 5px -15px;
  overflow: hidden;
}
#search .form-control {
  height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  background: #f1f1f1;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px 0 0 4px;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
#search .form-control::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #333333;
}
#search .form-control:-moz-placeholder {
  /* Firefox 18- */
  text-transform: uppercase;
  color: #333333;
}
#search .form-control::-moz-placeholder {
  /* Firefox 19+ */
  text-transform: uppercase;
  color: #333333;
}
#search .form-control:-ms-input-placeholder {
  text-transform: uppercase;
  color: #333333;
}
#search #search-btn {
  height: 30px;
  border: none;
  margin: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #333333;
  background: #f1f1f1;
  font-size: 17px;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
  border-radius: 0 4px 4px 0;
}
#search #search-btn:hover {
  background: #f1f1f1;
}
.advanced-search {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2.2px;
}
.advanced-search:active,
.advanced-search:visited,
.advanced-search:focus,
.advanced-search:hover {
  color: #ffffff;
  text-decoration: none;
}
.search-header {
  position: relative;
  z-index: 2;
}
.search-wrapper {
  position: absolute;
  right: 0;
  height: 100%;
}
.search-wrapper .search-btn {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 15px;
  padding: 7px;
  border: none;
  background: none;
  color: #ffffff;
}
.search-wrapper .search-btn:hover,
.search-wrapper .search-btn:active,
.search-wrapper .search-btn:focus {
  color: #ffffff;
  background: none;
  outline: none;
}
.navbar-toggle {
  margin: 0 20px 0 0;
  border: none;
  padding: 13px 10px;
  border-radius: 0;
  background: none;
}
.navbar-toggle:hover,
.navbar-toggle:focus {
  background: none !important;
}
.navbar-toggle:hover span.icon-bar,
.navbar-toggle:focus span.icon-bar {
  background-color: #ffdb3d;
}
.nav > li.mega-dropdown {
  position: static;
}
.nav > li.mega-dropdown.active > a.dropdown-toggle {
  background-color: #2f2f2f;
}
.nav > li.mega-dropdown.active > a.dropdown-toggle:hover {
  background-color: #2f2f2f;
}
.nav > li.mega-dropdown > ul.dropdown-menu {
  display: none;
  left: 0;
  right: 0;
  width: 100%;
  float: none;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #90B8D0;
  border-radius: 0;
  background: transparent url('/images/bg.meganav-o.png') top center repeat;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.nav > li.mega-dropdown > ul.dropdown-menu h3 {
  font-weight: 400;
  color: #dc0002;
  font-size: 14px;
  text-transform: uppercase;
}
.nav > li.mega-dropdown > ul.dropdown-menu h4 {
  font-size: 12px;
  padding: 0 15px;
  text-transform: uppercase;
  color: #4f4f4f;
}
.nav > li.mega-dropdown > ul.dropdown-menu p.aboutblurb {
  height: 75px;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.hidden-xs {
  padding: 25px 0;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs {
  background: #e6f6ff;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav {
  margin: 0;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li {
  border-top: 1px solid #90B8D0;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li:first-child,
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.first {
  border-top: none;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.dropdown-header {
  background: #ffdb3d;
  color: #000000;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.open > a {
  background: #d2eeff;
  border-bottom: 1px solid #90B8D0;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.open ul {
  background: #ffffff;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.open ul li {
  border: none;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li.open ul li a:active {
  color: #08a3f1;
}
.nav > li.mega-dropdown > ul.dropdown-menu li.visible-xs .navbar-nav li a {
  color: #353535;
}
.nav > li.mega-dropdown.hover > a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e6f6ff;
}
.nav > li.mega-dropdown .nav-stacked {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  text-transform: uppercase;
}
.nav > li.mega-dropdown .nav-stacked > li {
  margin: 0;
}
.nav > li.mega-dropdown .nav-stacked > li > a {
  line-height: 14px;
  padding: 7px 0;
  color: #4f4f4f;
}
.nav > li.mega-dropdown .nav-stacked > li > a:hover {
  color: #08a3f1;
  background: none;
}
.nav > li.mega-dropdown .nav-stacked.primary,
.nav > li.mega-dropdown .nav-stacked.secondary {
  font-size: 14px;
  text-transform: none;
}
.nav > li.mega-dropdown .nav-stacked.primary > li > a,
.nav > li.mega-dropdown .nav-stacked.secondary > li > a {
  color: #000000;
}
.nav > li.mega-dropdown .nav-stacked.primary > li > a:hover,
.nav > li.mega-dropdown .nav-stacked.secondary > li > a:hover {
  color: #08a3f1;
}
.nav > li.mega-dropdown .nav-stacked.primary > li > a small,
.nav > li.mega-dropdown .nav-stacked.secondary > li > a small {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
}
.nav > li.mega-dropdown .nav-stacked.secondary {
  padding-top: 0;
}
.nav > li.mega-dropdown .nav-stacked .btn {
  border: 1px solid #000000;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  margin-left: -10px;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.nav > li.mega-dropdown .nav-stacked .btn.btn-primary {
  background: #ffffff;
}
.nav > li.mega-dropdown .nav-stacked .btn.btn-primary:hover {
  background: #ffffff;
}
.nav > li.mega-dropdown .nav-stacked .btn:hover {
  border-color: #08a3f1;
}
.nav > li.mega-dropdown a.thumbnail {
  background: none;
  border: none;
  margin: 10px 0;
  padding: 0;
  color: #000000;
  font-weight: 600;
}
.nav > li.mega-dropdown a.thumbnail:hover {
  text-decoration: none;
  color: #08a3f1;
}
.nav > li.mega-dropdown a.thumbnail:hover > img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.nav > li.mega-dropdown a.thumbnail > img {
  border: 1px solid #000000;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.nav > li.mega-dropdown .panel {
  margin: 10px 0;
  border: 1px solid #b9babc;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.nav > li.mega-dropdown .panel a {
  display: block;
  color: #000000;
  font-weight: 600;
  margin-bottom: 0;
}
.nav > li.mega-dropdown .panel a:hover {
  color: #08a3f1;
}
.nav > li.mega-dropdown .panel h3 {
  margin-top: 0;
}
.nav > li.mega-dropdown .panel div.mega-dropdown-meeting-listing {
  margin-bottom: 12px;
}
.nav > li.mega-dropdown .panel span.mega-dropdown-meeting-date {
  font-weight: normal;
}
.nav > li.mega-dropdown .panel div.mega-dropdown-meeting-stacked-banner {
  max-height: 80px;
  float:none;
  margin:auto
}
.nav > li.mega-dropdown .panel img.mega-dropdown-meeting-banner {
  border: 1px solid #dddddd;
}
.nav.account .mega-dropdown > a {
  color: #ffdb3d !important;
}
.nav.account .mega-dropdown .navbar-toggle .icon-bar {
  background-color: #ffdb3d;
}
.nav.account .mega-dropdown.hover > a {
  color: #ffdb3d !important;
}
.nav.account .mega-dropdown.hover .navbar-toggle:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffdb3d;
}
.nav.account .mega-dropdown.hover .navbar-toggle .icon-bar {
  background-color: #ffdb3d;
}
.break {
  margin: 0;
  height: 8px;
}
.title {
  margin-top: 10px;
}
.title small {
  display: block;
  margin: 5px 0;
  text-transform: uppercase;
  color: #959595;
  font-size: 11px;
}
.portal {
  margin: 20px 0;
}
.portal img.img-circle {
  width: 60px;
  height: 60px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.portal:hover img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
/*----Main Content-----*/
/*-----Social Buttons-----*/
.social-share {
  margin-bottom: 20px;
}
.social-share a.btn {
  font-size: 14px;
  border-radius: 2px;
  margin-right: 8px;
  text-transform: uppercase;
  border: 1px solid;
}
.social-share a.btn.share-facebook {
  border-color: #3162a2;
  color: #3162a2;
}
.social-share a.btn.share-twitter {
  border-color: #2eb1eb;
  color: #2eb1eb;
}
.social-share a.btn.share-stumbleupon {
  border-color: #c15842;
  color: #c15842;
}
.social-share a.btn.share-general {
  border-color: #aaaaaa;
  color: #aaaaaa;
}
/*-----Article Buttons-----*/
.article-tools {
  margin-bottom: 20px;
}
.article-tools a.btn {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 8px;
  color: #7b7b7b;
  border: 1px solid;
  border-radius: 2px;
}
/*-----Article Links-----*/
.article-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.article-links li {
  margin: 20px 0;
}
.article-links li.icon-right a {
  padding-right: 30px;
}
.article-links li a {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.article-links li a.breakingnews:visited {
  color: #aaaaaa;
}
.article-links li a.hero {
  font-size: 20px;
  line-height: 24px;
}
.article-links li a .date {
  display: block;
  font-size: 10px;
  font-style: italic;
  color: #aaaaaa;
}
.article-links li a > i.fa {
  position: absolute;
  left: -20px;
  top: 2px;
  color: #aaaaaa;
}
.article-links li a span,
.article-links li a p.meta_institute {
  display: block;
  position: relative;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #909090;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.article-links li a span span.icon,
.article-links li a p.meta_institute span.icon {
  position: absolute;
  top: 0;
  right: -30px;
  text-transform: none;
  font-style: italic;
  font-size: 11px;
}
/* Override visited state for calendar links */
.calendar-links li a:visited {
  color: #333333 !important;
}
/*-----Individual Article Links-----*/
a.article-link:hover h2,
a.article-link:active h2,
a.article-link:hover p.meta_institute,
a.article-link:active p.meta_institute,
a.article-link:hover span,
a.article-link:active span {
  color: #aaaaaa;
}
a.article-link:visited h2 {
  color: #aaaaaa;
}
/*-----View Links-----*/
a.view-more {
  font-size: 12px;
  font-weight: 600;
  display: block;
  margin: 10px 0 30px;
}
/*-----Article Labels-----*/
b.label-type {
  font-weight: 300;
  text-transform: uppercase;
}
/*-----Scaffolding-----*/
#main-content.white {
  background: #ffffff;
}
.main {
  margin: 20px 0;
}
/*-----Toolbars-----*/
.toolbar {
  margin: 0;
}
.toolbar:before,
.toolbar:after {
  content: " ";
  display: table;
}
.toolbar:after {
  clear: both;
}
.toolbar select {
  color: #333333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #909090;
  max-width: 100%;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.toolbar select:focus {
  border-color: #909090;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.toolbar .btn-group .btn {
  background: transparent;
  padding: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 4px !important;
  color: #888;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.toolbar .btn-group .btn:hover {
  background: none;
  color: #333333;
}
.toolbar .btn-group .btn.active {
  color: #333333;
  background: #dedede;
}
.toolbar .btn-group .btn.active:hover {
  color: #333333;
  background: #dedede;
}
.gray .toolbar .btn-group .btn.active {
  background: #ffffff;
}
.gray .toolbar .btn-group .btn.active:hover {
  background: #ffffff;
}
.multimedia-toolbar {
  margin: 0 -10px;
  margin-bottom: 30px;
}
.multimedia-toolbar .search-sub {
  width: 315px;
}
.multimedia-toolbar .search-sub-mobile {
  margin-top: 1px;
  padding-left: 0;
  padding-right: 0;
  color: #333333;
  font-size: 12px;
  text-transform: uppercase;
}
.search-sub .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #909090;
  border-right: none;
  font-size: 12px;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.search-sub .form-control:before,
.search-sub .form-control:after {
  content: " ";
  display: table;
}
.search-sub .form-control:after {
  clear: both;
}
.search-sub .search-sub-btn {
  border-color: #909090;
  border-left: none;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.search-sub .search-sub-btn:hover,
.search-sub .search-sub-btn:active,
.search-sub .search-sub-btn:focus {
  background: #ffffff;
}
.search-sub .advanced-search {
  float: right;
  margin-top: 5px;
  color: #909090;
}
.gray .search-sub .form-control,
.gray .search-sub .search-sub-btn {
  background: transparent;
}
/*-----Home Page-----*/
.trending {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
}
.trending a {
  display: block;
  color: #1c1c1c;
}
.trending a img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.trending a:hover {
  color: #888888;
}
.trending a:hover header .meta_institute {
  color: #888888;
}
.trending a:hover img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.trending a:visited header h2 {
  color: #888888;
}
.trending .thumb {
  position: absolute;
  right: 0;
  top: 0;
  width: 90px;
  height: 50px;
  text-align: right;
}
.trending .thumb img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 50px;
}
.trending header h2 {
  font-size: 14px;
  margin: 0;
}
.trending.has-thumb {
  min-height: 50px;
}
.trending.has-thumb header {
  margin-right: 100px;
}
.trending.highlight {
  border: 1px solid #ccc;
  height: 150px;
}
.trending.highlight header {
  padding: 20px;
}
.trending.photo {
  height: 150px;
}
.trending.photo img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.trending.photo header {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.57);
  color: #ffffff;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.trending.photo header p.meta_institute {
  color: #ffffff;
}
.trending.photo a:hover img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.trending.photo a:hover header {
  color: #c3c3c3;
}
.trending.photo a:hover header p.meta_institute {
  color: #c3c3c3;
}
.trending.photo a:visited header h2 {
  color: #c3c3c3;
}
.trending.photo a:visited header p.meta_institute {
  color: #c3c3c3;
}
.trending.featured img {
  display: block;
  width: 340px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.trending.featured header {
  padding: 0;
  margin-top: 10px;
}
.meta_institute {
  margin: 2px 0 0 0;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: #909090;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.subject-articles {
  min-height: 220px;
}
.subject-links-mobile {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
}
.subject-links-mobile a {
  display: block;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 300;
  color: #1c1c1c;
  border-bottom: 1px solid #b9babc;
}
.subject-links-mobile a i.fa {
  float: right;
  font-size: 18px;
}
.subject-links-mobile li:last-child a {
  border-bottom: none;
}
/*-----Posts-----*/
.post {
  border-bottom: 1px solid #b9babc;
  padding: 0;
  margin: 0 -10px;
}
.post a {
  display: block;
  position: relative;
  padding: 0 10px;
}
.post a:before,
.post a:after {
  content: " ";
  display: table;
}
.post a:after {
  clear: both;
}
.post a header {
  padding: 18px 0;
  color: #333333;
}
.post a header div.reltime {
  margin-bottom: 2px;
}
.post a header small.meta_date {
  color: #aaaaaa;
  letter-spacing: -0.28px;
  font-size: 11px;
  text-transform: uppercase;
}
.post a header .meta_date_embargoed {
  color: #dc0002;
  letter-spacing: -0.28px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
.post a header .meta_date_embargoed_lite {
  letter-spacing: -0.28px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}
.post a header:hover header h2.post_title,
.post a header:visited header h2.post_title {
  color: #888888;
}
.post a header span {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
}
.post a .entry p.intro, .post a .entry div.intro {
  color: #505050;
  border-left: 2px solid #b9babc;
  padding-left: 10px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}
.post a .entry div.intro p {
  color: #505050;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}	
.post a .thumb {
  width: 87px;
  height: 87px;
  border: 1px solid #b9babc;
  background: #ffffff;
  padding: 4px;
  margin: 18px 0;
}
.post a:active header {
  color: #888;
}
.post a:active .entry p.intro, .post a:active .entry div.intro, .post a:active .entry div.intro p {
  color: #aaaaaa;
  border-color: #ccc;
}
.post a:active dl.meta dt {
  border-color: #ccc;
}
.post a:active .thumb img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.post a.has-thumb header,
.post a.has-thumb .entry,
.post a.has-thumb dl.meta {
  margin-right: 110px;
}

.post .accessinst {
  color: #505050;
  border-left: 2px solid #b9babc;
  padding-left: 10px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}

.post .accessinst a {
  display: inline-block;
}

tr.post:nth-child(even){
  background-color: #f2f2f2;
  margin-top:5px;
}
dl.meta {
  margin-top: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
}
dl.meta:before,
dl.meta:after {
  content: " ";
  display: table;
}
dl.meta:after {
  clear: both;
}
dl.meta dt {
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 2px solid #b9babc;
  padding-left: 10px;
  margin-bottom: 12px;
  width: auto;
  min-width: 100px;
}
dl.meta dt.yellow {
  border-color: #ffb134;
}
dl.meta dt.green {
  border-color: #66bc33;
}
dl.meta dt.purple {
  border-color: #6666cc;
}
dl.meta dt.red {
  border-color: #f6303d;
}
dl.meta dd {
  margin-left: 100px;
}
dl.meta.stacked dt {
  float: none;
  margin-bottom: 0;
  margin-top: 20px;
}
dl.meta.stacked dd {
  margin-left: auto;
  padding-left: 10px;
  border-left: 2px solid #b9babc;
}
dl.meta.stacked dd.yellow {
  border-color: #ffb134;
}
dl.meta.stacked dd.green {
  border-color: #66bc33;
}
dl.meta.stacked dd.purple {
  border-color: #6666cc;
}
dl.meta.stacked dd.red {
  border-color: #f6303d;
}
.next-date,
.archive-search-box {
  display: block;
  color: #aaaaaa;
  margin: 30px -20px 0;
}
.next-date > span,
.archive-search-box > span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  background: #0088cc;
  color: #ffffff;
  padding: 15px 0;
}
.next-date > span i.fa,
.archive-search-box > span i.fa {
  font-size: 16px;
}
.next-date .panel,
.archive-search-box .panel {
  margin: 0;
  position: relative;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.next-date .panel .panel-body,
.archive-search-box .panel .panel-body {
  padding: 30px 135px 52px 30px;
}
.next-date .panel i.arrow,
.archive-search-box .panel i.arrow {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 42px;
  height: 131px;
  margin-top: -65px;
  background: transparent url('/images/next-day-chevron_84x232.png') 0 0 no-repeat;
  background-size: 42px 131px;
}
.next-date h2,
.archive-search-box h2 {
  font-weight: 400;
  margin: 0 0 20px;
}
.next-date h4,
.archive-search-box h4 {
  margin: 30px 0 0;
}
.next-date span.author,
.archive-search-box span.author {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
}
.next-date:hover {
  color: #888888;
  background: none;
}
.next-date:hover .panel i.arrow {
  background-image: url('/images/next-day-chevron_84x232-hover.png');
}
.archive-search-box .panel .panel-body {
  padding: 30px;
}
.archive-search-box .search-sub .form-control {
  border-color: #aaaaaa;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.archive-search-box .search-sub .form-control::-webkit-input-placeholder {
  color: #aaaaaa;
}
.archive-search-box .search-sub .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #aaaaaa;
}
.archive-search-box .search-sub .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaaaaa;
}
.archive-search-box .search-sub .form-control:-ms-input-placeholder {
  color: #aaaaaa;
}
.archive-search-box .search-sub .form-control.placeholder {
  color: #aaaaaa;
}
.archive-search-box .search-sub .search-sub-btn {
  border-color: #aaaaaa;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.archive-search-box .search-sub .search-sub-btn i.fa {
  color: #aaaaaa;
}
.archive-search-box .mobile-archive-search-box {
  margin-bottom: 30px;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control {
  border-color: #4f4f4f;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control::-webkit-input-placeholder {
  color: #505050;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #505050;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #505050;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control:-ms-input-placeholder {
  color: #505050;
}
.archive-search-box .mobile-archive-search-box .search-sub .form-control.placeholder {
  color: #505050;
}
.archive-search-box .mobile-archive-search-box .search-sub .search-sub-btn {
  border-color: #4f4f4f;
}
.archive-search-box .mobile-archive-search-box .search-sub .search-sub-btn i.fa {
  color: #505050;
}
.multimedia-gallery > .row {
  margin-left: -20px;
  margin-right: -20px;
}
.multimedia-gallery ul.pagination {
  margin: 55px 0;
}
.multimedia-item {
  padding: 10px;
}
.multimedia-item a {
  display: block;
  position: relative;
  padding: 0 5px;
  background: #ffffff;
}
.multimedia-item a .label {
  position: absolute;
  top: 1px;
  right: 1px;
  border-radius: 0;
  background: rgba(203, 33, 33, 0.9);
  padding: 10px 12px;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  z-index: 2;
}
.multimedia-item a .label i.fa {
  margin-right: 8px;
  font-size: 13px;
}
.multimedia-item a .center-image {
  margin: 5px 0;
  height: 134px;
}
.multimedia-item a .center-image img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.multimedia-item a .multimedia_card {
  height: 150px;
  margin: 0 -5px;
  border-top: 1px solid #dedede;
  padding: 0 5px;
}
.multimedia-item a .multimedia_card h3 {
  margin: 5px 0 0;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.multimedia-item a .multimedia_card h3 small {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  margin-top: 3px;
}
.multimedia-item a:hover .center-image img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.multimedia-item a:hover .multimedia_card h3 {
  color: #888888;
}
.multimedia-item a:hover .multimedia_card h3 small {
  color: #888888;
}
/*-----Portal Trending 4/21/16-----*/
.headline header h2 {
  color: #333333;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 0;
}
.headline header .meta_institute {
  font-size: 12px;
  line-height: 17px;
}
.headline header .reltime {
  color: #333333;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 5px;
}
/*-----End Portal Trending 4/21/16-----*/
.article .page_title {
  color: #2b2b2b;
  margin: 25px 0 0;
}
.article .page_title span.type {
  text-transform: uppercase;
}
.article p.summary {
  margin-top: 5px;
  font-size: 17px;
  font-style: italic;
  font-weight: 300;
  color: #7b7b7b;
}
.article p.meta_institute {
  margin-bottom: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #2b2b2b;
}
.article .featured_image {
  position: relative;
  margin-bottom: 20px;
  margin-left: -10px;
  margin-right: -10px;
}
.article .featured_image.single .center-image {
  background: #ffffff;
  border: 1px solid #b9babc;
  padding: 10px;
}
.article .featured_image.single img {
  width: auto;
}
.article .featured_image img {
  width: 100%;
  height: auto;
}
.article .featured_image .caption {
  margin-left: 10px;
  margin-right: 10px;
}
.article .featured_image .details {
  margin: 0 20px;
}
.article .featured_image .details .well {
  background: none;
  margin: 20px 0 40px;
  padding: 0 0 0 10px;
  border-left: 2px solid #dddddd;
}
.article .featured_image .details .well h4 {
  text-transform: uppercase;
  font-size: 13.5px;
  line-height: 23px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}
.article .featured_image .details .well p {
  font-size: 13.5px;
  line-height: 23px;
  margin-bottom: 25px;
  color: #333333;
}
.article .featured_image .details .well p:last-child {
  margin-bottom: 0;
}
.article .featured_image .details .well.primary {
  padding: 25px;
}
.article .featured_image .details .well.yellow {
  background: #fffaea;
  border-color: #ffdb3d;
}
.article .featured_image .details .well.yellow a {
  color: #333333;
  font-weight: 600;
}
.article .featured_image .details .well.yellow a:hover,
.article .featured_image .details .well.yellow a:active,
.article .featured_image .details .well.yellow a:visited {
  color: #aaaaaa;
}
.article .toolbar {
  margin: 0;
}
.article .caption {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.4;
}
.article .caption a {
  text-transform: none;
  font-weight: 600;
}
.article .caption p.credit {
  font-weight: 400;
  color: #aaaaaa;
}
.article .entry {
  font-size: 14px;
  line-height: 23px;
  color: #333333;
}
.article .entry p {
  margin-top: 5px;
  margin-bottom: 15px;
}
.article .thumbnail {
  width: 288px;
  border: none;
  border-radius: 0;
  padding: 0;
}
.article .thumbnail.pull-right {
  margin-left: 34px;
}
.article .thumbnail.pull-left {
  margin-right: 34px;
}
.article .thumbnail .img-wrapper {
  display: inline-block;
  width: 288px;
  height: 288px;
  text-align: center;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #aaaaaa;
}
.article .thumbnail .img-wrapper:after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.article .thumbnail .img-wrapper img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 272px;
  max-height: 272px;
  vertical-align: middle;
}
.article .thumbnail .caption {
  padding: 0;
}
.article .thumbnail .caption p {
  font-size: 12px;
  line-height: 1.4;
  margin: 0 0 10px;
}
.article .article_disclaimer {
  margin: 20px 0 25px;
}
.article .article_disclaimer p {
  font-size: 13.5px;
  line-height: 23px;
  color: #333333;
}
.article .article_disclaimer p:last-child {
  margin-bottom: 0;
}
/*-----Sidebar Content-----*/
#sidebar-content {
  background: #ffffff;
}
#sidebar-content h2.subhead:first-child {
  margin-top: -20px;
}
#sidebar-content hr {
  margin: 0 -20px;
  border-color: #b9babc;
  border-style: solid;
}
#sidebar-content hr.major {
  height: 8px;
  background: #f1f1f1;
  margin-right: -21px;
  border-bottom: 1px solid #b9babc;
}
#sidebar-content .nav-stacked > li + li {
  margin: 2px 0;
}
#sidebar-content .widget {
  margin: 20px 0 35px 0;
}
#sidebar-content .widget h3.widget-title {
  text-align: left;
  text-transform: none;
  font-size: 14px;
  color: #222222;
  margin: 0 -20px 18px 0;
  padding: 10px 15px;
  border-left: 4px solid;
}
#sidebar-content .widget h3.widget-title.blue {
  background: #D2EDF6;
  border-color: #96E0F9;
}
#sidebar-content .widget h3.widget-title.red {
  background: #fee5e0;
  border-color: #fea09e;
}
#sidebar-content .widget h3.widget-title.yellow {
  background: #feedcf;
  border-color: #fbd894;
}
#sidebar-content .widget h3.widget-title.green {
  background: #dbfffc;
  border-color: #27ccc0;
}
#sidebar-content .widget h3.widget-title.purple {
  background: #f7ebff;
  border-color: #c299ff;
}
#sidebar-content .widget h3.widget-title.climate-feature {
  background: #d8e4eb;
  border-color: #005080;
}
#sidebar-content .widget h3.widget-title.climate-news {
  background: none;
  border-color: #005080;
  color: #b9babc;
}
#sidebar-content .widget h3.widget-title.marine-feature {
  background: #5af0da;
  border-color: #008875;
}
#sidebar-content .widget h3.widget-title.marine-news {
  background: #dbf6f7;
  border-color: #008875;
}
#sidebar-content .widget h3.widget-title.cancer-feature {
  background: #f8e1e3;
  border-color: #7f222a;
}
#sidebar-content .widget h3.widget-title.cancer-news {
  background: rgba(212, 57, 70, 0.88);
  border-color: #d43946;
  color: #ffffff;
}
#sidebar-content .widget h3.widget-title.seminar-feature {
  background: #6dace2;
  border-color: #4294da;
  color: #ffffff;
}
#sidebar-content .widget h4.widget-subtitle {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 4px;
  font-size: 13.5px;
  font-weight: 600;
  color: #2b2b2b;
  text-transform: uppercase;
  border-bottom: 1px solid #b9babc;
}
#sidebar-content .widget .ad {
  position: relative;
  border-color: #000000;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
  margin: 12px -8px;
  max-width: 302px;
}
#sidebar-content .widget .ad.single {
  margin-bottom: -22px;
}
#sidebar-content .widget .ad:first-child {
  margin-top: -8px;
}
#sidebar-content .widget .ad .byline {
  position: absolute;
  top: 0;
  left: 0;
  background: #333333;
  background: rgba(0, 0, 0, 0.6);
  padding: 2px 15px;
  color: #ffffff;
  font-size: 10px;
  text-transform: uppercase;
}
#sidebar-content .widget .sponsored_content header {
  /* BEGIN NEW BLOCK - Sponsored Content */
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.57);
  color: #ffffff;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#sidebar-content .widget .sponsored_content header h2 {
  font-size: 14px;
  margin: 0;
}
#sidebar-content .widget .sponsored_content header p.meta_institute {
  color: #ffffff;
}
#sidebar-content .widget .article-links a .breakingnews:visited {
  color: #aaaaaa;
}
#sidebar-content .widget .article-links a:hover,
#sidebar-content .widget .article-links a:active {
  color: #aaaaaa;
}
#sidebar-content .widget .article-links a:hover span,
#sidebar-content .widget .article-links a:active span,
#sidebar-content .widget .article-links a:hover p.meta_institute,
#sidebar-content .widget .article-links a:active p.meta_institute {
  color: #aaaaaa;
}
#sidebar-content .widget .twitter-feed-header {
  width: 290px;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  margin: 0px auto 25px auto;
}
#sidebar-content .widget .twitter-feed-header-hashtag {
  color: #333333;
}
#sidebar-content .widget .twitter-feed-header-link {
  color: #1DA1F2;
}
#sidebar-content .widget .twitter-feed-content {
  width: 290px;
  margin: 0 auto;
}
#sidebar-content div.sponsored_content_whatsthis {
  width: 300px;
  margin: 10px auto;
}
#sidebar-content div.sponsored_content_whatsthis a {
  font-style: italic;
  color: #888888;
}
.subject-links {
  font-size: 15px;
}
.subject-links .btn {
  display: block;
  border: 1px solid #000000;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  text-align: left;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.subject-links .btn.btn-red {
  border-color: transparent;
  color: #dc0002;
}
.subject-links .btn.btn-red .fa {
  font-size: 20px;
}
.subject-links .btn.btn-red:hover {
  color: #dc0002;
}
.subject-links .break {
  height: 30px;
}
.subject-links .nav li a {
  color: #222222;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 7px 15px;
  z-index: 0;
  behavior: url('/css/vendor/pie/PIE.htc');
}
.subject-links .nav li a:hover {
  background: #ffffff;
  color: #222222;
  border-color: #dc0002;
}
.subject-links .nav li a .fa {
  font-size: 18px;
}
.subject-links .nav li.active a {
  border-color: #dc0002;
  background: #ffffff;
  font-weight: 600;
}
a.agency {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0;
  display: table;
  border: none;
  background: none;
  color: #005283;
  font-size: 15.25px;
  font-weight: 300;
}
a.agency:before,
a.agency:after {
  content: " ";
  display: table;
}
a.agency:after {
  clear: both;
}
a.agency img {
  max-width: 60px;
  max-height: 60px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.agency .btn {
  border: none !important;
  display: table-cell !important;
  vertical-align: middle;
  text-align: left;
  padding-left: 20px;
  width: 100%;
}
a.agency .fa {
  font-size: 16px;
}
a.agency:hover,
a.agency:active,
a.agency:focus {
  background: none;
}
a.agency:hover .btn,
a.agency:active .btn,
a.agency:focus .btn {
  color: #0e71ab;
}
a.agency:hover img,
a.agency:active img,
a.agency:focus img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
a.agency:visited .btn {
  color: #005283;
}
.widget-content {
  margin-left: 20px;
}
.widget-content hr {
  margin: 40px 0 !important;
}
aside.more a {
  color: #333333;
}
aside.more a p.meta_institute {
  color: #333333;
}
aside.more a:hover {
  color: #aaaaaa;
}
aside.more a:hover p.meta_institute {
  color: #aaaaaa;
}
aside.more a.inactive {
  color: #aaaaaa;
  cursor: default;
}
aside.more a.inactive p.meta_institute {
  color: #aaaaaa;
}
aside.more h3 {
  margin: 18px 0 2px;
  line-height: 20px;
  font-size: 17px;
  font-weight: 500;
  color: #888888;
}
aside.more p.meta_institute {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
}
aside.more dl.meta {
  margin: 0;
}
ul.tags {
  margin: 0 -3px 10px;
  padding: 0;
  list-style: none;
}
ul.tags:before,
ul.tags:after {
  content: " ";
  display: table;
}
ul.tags:after {
  clear: both;
}
ul.tags li {
  float: left;
  margin: 3px;
}
ul.tags li a {
  display: inline-block;
  background: #b9babc;
  color: #ffffff;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 11.5px;
}
ul.tags li a:hover {
  background: #aaaaaa;
}
ul.tags li.active a {
  background: #888888;
}
.translations-nav a {
  font-size: 12.5px;
  display: block;
  margin-bottom: 20px;
  color: #222222;
}
.translations-nav a.red {
  color: #dc0002;
}
.translations-nav a:hover {
  color: #888888;
}
.translations-nav a i.fa {
  font-size: 18px;
}
.translations-nav.pull-up {
  margin-bottom: -25px;
}
a.view-all {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  /*mobile*/
  margin: 30px 0;
  padding: 12px 0;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 3px;
  behavior: url('/css/vendor/pie/PIE.htc');
}
a.multimedia {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none;
  border: none;
  margin: 5px 0;
  padding: 0;
}
a.multimedia:before,
a.multimedia:after {
  content: " ";
  display: table;
}
a.multimedia:after {
  clear: both;
}
a.multimedia .center-image {
  margin-right: 10px;
  padding: 3px;
  width: 87px;
  height: 87px;
  border: 1px solid #b9babc;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.multimedia img {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.multimedia .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #333333;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.multimedia .desc span {
  display: block;
  text-transform: uppercase;
  font-weight: 300;
}
a.multimedia .desc .view-more {
  color: #08a3f1;
  color: #0088cc;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.multimedia:hover,
a.multimedia:visited,
a.multimedia:focus {
  background: none !important;
}
a.multimedia:hover .desc,
a.multimedia:active .desc {
  color: #aaaaaa;
}
a.multimedia:hover .desc .view-more,
a.multimedia:active .desc .view-more {
  color: #08a3f1;
}
a.multimedia:hover .center-image,
a.multimedia:active .center-image {
  background: #f1f1f1;
}
a.multimedia:hover img,
a.multimedia:active img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
a.multimedia.active {
  background: none;
}
a.multimedia.active .desc {
  color: #aaaaaa;
}
a.multimedia.active .center-image {
  background: #f1f1f1;
}
a.multimedia.active img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
a.multimedia.active:hover {
  background: none;
}
a.multimedia.active:hover .desc {
  color: #aaaaaa;
}
a.multimedia .mm_sidebar {
  font-size: 14px;
  color: #333333;
}
a.multimedia .mm_sidebar span {
  font-size: 10px;
  color: #aaaaaa;
  display: block !important;
  text-transform: uppercase;
  font-weight: 400;
}
.widget .portal {
  text-align: center;
  height: 150px;
}
.widget .portal a {
  display: block;
  padding: 0 20px;
}
.widget .portal a:hover .title {
  color: #888888;
}
.widget .portal a:hover .title span {
  color: #08a3f1;
}
.widget .portal .title {
  font-size: 14px;
  color: #333333;
}
.widget .portal .title span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #0088cc;
}
.contact-info,
.original-source {
  font-size: 13.25px;
}
/*-----Language Portals-----*/
img.language-reporter-logo {
  display: block;
  max-width: 270px;
  margin: 35px auto 35px auto;
}
/*-----Form Content-----*/
.panel {
  border-radius: 0;
}
.panel.panel-default .panel-body {
  border-radius: 0;
}
.panel.panel-default .panel-body .form-group input {
  border-bottom: 1px solid #bbb;
}
.panel.panel-default .panel-body select {
  color: #000000;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  height: 35px;
  margin-top: 10px;
  width: 100%;
  z-index: 0;
}
.panel.panel-default .panel-body hr {
  border-color: #bbb;
  border-style: solid;
  margin-top: 15px;
}
.panel.panel-default .panel-body .btn-group input[type="submit"] {
  background: #dc0002;
  border: none;
  border-bottom: 1px solid #dc0002;
  border-radius: 1px;
  color: #ffffff;
  padding: 20px 40px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.panel.panel-default .panel-body .btn-group input[type="submit"]:hover {
  background: #f60002;
}
/*-----Footer-----*/
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  clear: both;
  background: #606060;
  color: #ffffff;
  font-weight: 300;
  padding: 35px 0 25px;
}
#footer a {
  color: #ffffff;
  line-height: 39px;
  white-space: nowrap;
}
#footer a i.fa {
  margin-right: 10px;
  font-size: 16px;
  width: 16px;
}
#footer a:hover {
  color: #C3C3C3;
}
#footer a:hover i.fa-eurekalert {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#footer .stack-5 a {
  line-height: 30px;
}
#footer .logo {
  width: 60%;
  max-width: 200px;
}
#footer .logo img {
  max-width: 100%;
}
#footer .logo img.image-hidden-xs {
  display: none;
}
#footer .logo img.image-visible-xs {
  display: inline-block;
}
#footer .brand {
  width: 40%;
  max-width: 110px;
}
#footer p.copy {
  line-height: 30px;
}
#footer p.copy a {
  text-decoration: underline;
}
@media (max-width: 568px) {
  #navigation > .navbar > .container {
    padding: 0;
  }
  #navigation > .navbar.navbar-inverse .nav-pills {
    margin: 0 -15px;
  }
  #navigation > .navbar.navbar-inverse > .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .logo {
    width: 65%;
    margin-left: -10px;
  }
  .logo img {
    width: 100%;
    height: auto;
  }
  .brand {
    width: 33%;
  }
  .brand img {
    max-width: 100%;
    max-height: 100%;
  }
  .search-header {
    padding: 0 5px;
  }
  .search-wrapper .search-btn {
    right: 10px;
  }
  .navbar.navbar-inverse .nav-pills > li > a {
    font-size: 11px;
    padding: 15px 8px;
  }
  .navbar-toggle {
    margin: 1px -5px 0 0;
    padding: 15px;
  }
  .article .thumbnail {
    float: none !important;
    margin: 0 auto 20px !important;
  }
  .multimedia-item a .multimedia_card {
    height: 150px;
  }
}
@media (max-width: 767px) {
  body {
    margin-bottom: 0 !important;
  }
  .trending header h2 {
    font-size: 17px;
    line-height: 20px;
  }
  .trending header .meta_institute {
    font-size: 12px;
    line-height: 17px;
  }
  .article-links li a {
    font-size: 17px;
    line-height: 20px;
  }
  .article-links li a p.meta_institute {
    font-size: 12px;
    line-height: 17px;
  }
  #footer {
    position: relative;
    bottom: 0;
  }
  #sidebar-content .widget .ad {
    margin: 0 auto;
  }
  div.thumbnail {
    margin: 20px auto;
  }
}
@media (min-width: 768px) {
  body {
    background: #f1f1f1;
  }
  h1.page_title {
    margin: 25px 0;
  }
  #main-content,
  #sidebar-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  #content {
    background: #ffffff;
  }
  .gray #content {
    background: none;
  }
  #sidebar-content {
    background: #f1f1f1;
    padding-top: 10px;
  }
  #sidebar-content.white {
    background: #ffffff;
  }
  #search form {
    margin-left: 0;
    margin-right: 0;
  }
  #search #search-btn {
    color: #cb2121;
  }
  .nav > li.mega-dropdown.hover > a:after {
    border-left-width: 6px;
    border-right-width: 6px;
    border-bottom: 6px solid #ffffff;
  }
  #footer .logo img.image-visible-xs {
    display: none;
  }
  #footer .logo img.image-hidden-xs {
    display: inline-block;
  }
  .portal > a {
    display: block;
    color: #000000;
  }
  .portal > a .title {
    color: #000000;
  }
  .portal > a:hover {
    color: #08a3f1;
  }
  .portal > a:hover .title {
    color: #000000;
  }
  .portal .inverse {
    display: block;
    position: absolute;
    width: 100%;
    top: 200px;
    left: 0;
    font-weight: 600;
  }
  .form-control.inline {
    width: auto;
    min-width: 200px;
  }
  .toolbar {
    margin: 0;
  }
  .toolbar .btn-group .btn {
    padding: 8px 12px;
  }
  .multimedia-toolbar {
    margin: 0 0 20px 0;
  }
  .multimedia-gallery > .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .post {
    margin: 0 -20px;
    padding: 0 20px;
    border-bottom: 2px solid #f1f1f1;
  }
  .post a {
    padding: 0;
  }
  .post a .thumb {
    position: absolute;
    right: 0;
    width: 150px;
    height: 150px;
    padding: 8px;
  }
  .post a:hover {
    background: none;
  }
  .post a.has-thumb header,
  .post a.has-thumb .entry,
  .post a.has-thumb dl.meta {
    margin-right: 175px;
  }
  a.view-all {
    margin: 20px 0;
    padding: 15px 0;
    text-transform: none;
    border: none;
    border-radius: 0;
    behavior: url('/css/vendor/pie/PIE.htc');
  }
  .next-date,
  .archive-search-box {
    border-radius: 4px;
    margin: 50px 0;
    border: 1px solid #aaaaaa;
    z-index: 0;
    behavior: url('/css/vendor/pie/PIE.htc');
  }
  .next-date:hover {
    border-color: #08a3f1;
  }
  .next-date:hover .panel {
    background: #ecf4f8;
  }
  .article .featured_image {
    margin-left: 0;
    margin-right: 0;
  }
  .article .featured_image img {
    max-height: 700px;
  }
  .article .featured_image .caption {
    margin-left: 0;
    margin-right: 0;
  }
  .article .featured_image .details {
    margin: 0;
  }
  .article-tools a.btn {
    border: none;
    margin-left: 5px;
  }
  .multimedia-item a {
    padding: 0 20px;
  }
  .multimedia-item a .center-image {
    margin: 10px 0;
    height: 200px;
  }
  .multimedia-item a .multimedia_card {
    margin: 0 -20px;
    padding: 0 20px;
    height: 160px;
  }
  .multimedia-item a .multimedia_card h3 {
    margin: 15px 0 0;
  }
  /*-----Portal "headline" Trending-----*/
  .headline {
    margin: 0 0 25px;
  }
  /*-----Language Portals-----*/
  img.language-reporter-logo {
    margin: 35px 0px 35px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h2.subhead {
    margin: 30px 0 30px;
  }
  .text-center-sm {
    text-align: center;
  }
  hr.major {
    border-color: #cdcdcd !important;
  }
  #sidebar-content .widget h4.widget-subtitle {
    border: none;
    margin-top: 20px;
  }
  #sidebar-content .widget .ad {
    margin: 0 auto;
  }
  #sidebar-content .widget-content {
    margin: 0 20px;
  }
  aside.more dl.meta {
    margin-top: 20px;
  }
  dl.meta.stacked dt {
    float: left;
    margin-top: 0;
    margin-bottom: 12px;
  }
  dl.meta.stacked dd {
    margin-left: 100px;
    border: none;
    padding-left: 0;
  }
  a.multimedia {
    margin: 20px 0;
  }
  a.multimedia .center-image {
    width: 150px;
    height: 150px;
    padding: 8px;
    margin-right: 20px;
  }
  a.multimedia .desc span {
    display: inline;
  }
  .post a.has-thumb {
    min-height: 185px;
  }
}
@media (min-width: 992px) {
  body {
    background: #f1f1f1 url('/images/bg.gradient.png') 0 140px repeat-x;
  }
  .navbar {
    background: #cb2121 url('/images/bg.header.webp') center center no-repeat;
  }
  h2.subhead {
    text-align: left;
    border-bottom: 1px solid #b9babc;
    margin-top: 25px;
    margin-bottom: 10px;
    padding-bottom: 3px;
  }
  .nav > li.mega-dropdown ul.dropdown-menu {
    height: 290px;
  }
  .post {
    border: none;
    margin: 0;
    padding: 0;
  }
  .post a .thumb {
    width: 87px;
    height: 87px;
    border: 1px solid #b9babc;
    background: #ffffff;
    padding: 4px;
  }
  .post a.has-thumb {
    min-height: 125px;
  }
  .post a.has-thumb header,
  .post a.has-thumb .entry,
  .post a.has-thumb dl.meta {
    margin-right: 110px;
  }
  .article-links li {
    margin: 0 0 20px 0;
  }
  a.view-all {
    margin: 0;
    padding: 10px 0 0 0;
  }
  .next-date .panel,
  .archive-search-box .panel {
    background-image: -webkit-linear-gradient(top, #ffffff 15%, #f1f1f1 100%);
    background-image: -o-linear-gradient(top, #ffffff 15%, #f1f1f1 100%);
    background-image: linear-gradient(to bottom, #ffffff 15%, #f1f1f1 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff1f1f1', GradientType=0);
  }
  .article .featured_image img {
    max-height: 600px;
  }
  .article-tools a.btn,
  .social-share a.btn {
    font-size: 12px;
  }
  #content {
    background: none;
  }
  #main-content.has-sidebar,
  #sidebar-content {
    border-right: 1px solid #b9babc;
  }
  #sidebar-content {
    background: #ffffff;
    padding-top: 25px;
  }
  .multimedia-item a .center-image {
    height: 200px;
  }
  .multimedia-item a .multimedia_card {
    height: 150px;
  }
  .multimedia-item.image .multimedia_card {
    background: url('/images/bg.media-photo.jpg') right bottom no-repeat;
  }
  .multimedia-item.video .multimedia_card {
    background: url('/images/bg.media-video.jpg') right bottom no-repeat;
  }
  .multimedia-item.audio .multimedia_card {
    background: url('/images/bg.media-audio.jpg') right bottom no-repeat;
  }
  .trending.photo,
  .trending.highlight {
    height: 130px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 992px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 992px), only screen and (min-device-pixel-ratio: 2) and (min-width: 992px), only screen and (min-resolution: 192dpi) and (min-width: 992px), only screen and (min-resolution: 2dppx) and (min-width: 992px) {
  .multimedia-item.photo .multimedia_card {
    background: url('/images/bg.media-photo@2x.png') right bottom no-repeat;
    background-size: 170px 100px;
  }
  .multimedia-item.video .multimedia_card {
    background: url('/images/bg.media-video@2x.png') right bottom no-repeat;
    background-size: 170px 100px;
  }
  .multimedia-item.audio .multimedia_card {
    background: url('/images/bg.media-audio@2x.png') right bottom no-repeat;
    background-size: 170px 100px;
  }
}
@media print {
  /* "equal-height" is causing IE to print out 100+ pages for news releases
     This should fix that issue */
  .equal-height > * {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  /* Bootstrap by default shows the full URL for links in print view.  This prevents that behavior. */
  a[href]:after {
    content: "";
  }
}

.audio-snippet-row{
   display: flex;
}
.audio-snippet-column{
   flex: 50%;
   padding:10px;
}
 img.audio-snippet{
    max-width: 100%;
    height: auto;
 }


/* for accordion snippet */
/* unvisited link */
.accodion-link{
  color: black;
  text-decoration: none;
  outline: none !important;
  border:0 !important;
}

/* visited link */
.accodion-link:visited {
  color: black;
  text-decoration: none;
  outline: none;
  border:0;
}

/* mouse over link */
.accodion-link:hover {
  color: black;
  text-decoration: none;
  outline: none;
  border:0;
}

/* selected link */
.accodion-link:active {
  color: black;
  text-decoration: none;
  outline: none;
  border:0;
}

/* selected focus */
.accodion-link:focus {
  color: black;
  text-decoration: none;
  outline: none;
  border:0;
}

/* Center the loader */
#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid gray;/*#f3f3f3;*/
  border-radius: 50%;
  border-top: 16px solid red; /*#3498db;*/
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  visibility: hidden;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

/* Video Player Overrides mkplayer/bitmovin */
.bitmovinplayer-poster { background-color: black !important; }
.bmpui-image { background-color: #00000033 !important; }
