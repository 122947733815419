.ah * {
  box-sizing: border-box;
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.ah.row::after {
  content: "";
  clear: both;
  display: table;
}

.ah html {
  font-family: "Lucida Sans", sans-serif;
}

.ah.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.ah.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ah.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color :#33b5e5;
  color: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.ah.menu li:hover {
  background-color: #0099cc;
}

.ah-main {

}

#main-content{

}


/*-----ahsidebar Content-----*/
#ahsidebar-content {
}
#ahsidebar-content h2.subhead:first-child {
  margin-top: -20px;
}
#ahsidebar-content hr {
  margin: 0 -20px;
}
#ahsidebar-content hr.major {
  height: 8px;
  margin-right: -21px;
  border-bottom: 0px solid #b9babc;
}
#ahsidebar-content .nav-stacked > li + li {
  margin: 2px 0;
}
#ahsidebar-content .widget {
  margin: 20px 0 35px 0;
}
#ahsidebar-content .widget h3.widget-title {
  text-align: left;
  text-transform: none;
  font-size: 14px;
  color: #222222;
  margin: 0 -20px 18px 0;
  padding: 10px 15px;
  border-left: 4px solid;
}
#ahsidebar-content .widget h3.widget-title.blue {
  background: #D2EDF6;
  border-color: #96E0F9;
}
#ahsidebar-content .widget h3.widget-title.red {
  background: #fee5e0;
  border-color: #fea09e;
}
#ahsidebar-content .widget h3.widget-title.yellow {
  background: #feedcf;
  border-color: #fbd894;
}
#ahsidebar-content .widget h3.widget-title.green {
  background: #dbfffc;
  border-color: #27ccc0;
}
#ahsidebar-content .widget h3.widget-title.purple {
  background: #f7ebff;
  border-color: #c299ff;
}
#ahsidebar-content .widget h3.widget-title.climate-feature {
  background: #d8e4eb;
  border-color: #005080;
}
#ahsidebar-content .widget h3.widget-title.climate-news {
  background: none;
  border-color: #005080;
  color: #b9babc;
}
#ahsidebar-content .widget h3.widget-title.marine-feature {
  background: #5af0da;
  border-color: #008875;
}
#ahsidebar-content .widget h3.widget-title.marine-news {
  background: #dbf6f7;
  border-color: #008875;
}
#ahsidebar-content .widget h3.widget-title.cancer-feature {
  background: #f8e1e3;
  border-color: #7f222a;
}
#ahsidebar-content .widget h3.widget-title.cancer-news {
  background: rgba(212, 57, 70, 0.88);
  border-color: #d43946;
  color: #ffffff;
}
#ahsidebar-content .widget h3.widget-title.seminar-feature {
  background: #6dace2;
  border-color: #4294da;
  color: #ffffff;
}
#ahsidebar-content .widget h4.widget-subtitle {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 4px;
  font-size: 13.5px;
  font-weight: 600;
  color: #2b2b2b;
  text-transform: uppercase;
  border-bottom: 1px solid #b9babc;
}
#ahsidebar-content .widget .ad {
  position: relative;
  border-color: #000000;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
  margin: 12px -8px;
  max-width: 302px;
}
#ahsidebar-content .widget .ad.single {
  margin-bottom: -22px;
}
#ahsidebar-content .widget .ad:first-child {
  margin-top: -8px;
}
#ahsidebar-content .widget .ad .byline {
  position: absolute;
  top: 0;
  left: 0;
  background: #333333;
  background: rgba(0, 0, 0, 0.6);
  padding: 2px 15px;
  color: #ffffff;
  font-size: 10px;
  text-transform: uppercase;
}
#ahsidebar-content .widget .sponsored_content header {
  /* BEGIN NEW BLOCK - Sponsored Content */
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.57);
  color: #ffffff;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#ahsidebar-content .widget .sponsored_content header h2 {
  font-size: 14px;
  margin: 0;
}
#ahsidebar-content .widget .sponsored_content header p.meta_institute {
  color: #ffffff;
}
#ahsidebar-content .widget .article-links a .breakingnews:visited {
  color: #aaaaaa;
}
#ahsidebar-content .widget .article-links a:hover,
#ahsidebar-content .widget .article-links a:active {
  color: #aaaaaa;
}
#ahsidebar-content .widget .article-links a:hover span,
#ahsidebar-content .widget .article-links a:active span,
#ahsidebar-content .widget .article-links a:hover p.meta_institute,
#ahsidebar-content .widget .article-links a:active p.meta_institute {
  color: #aaaaaa;
}
#ahsidebar-content .widget .twitter-feed-header {
  width: 290px;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  margin: 0px auto 25px auto;
}
#ahsidebar-content .widget .twitter-feed-header-hashtag {
  color: #333333;
}
#ahsidebar-content .widget .twitter-feed-header-link {
  color: #1DA1F2;
}
#ahsidebar-content .widget .twitter-feed-content {
  width: 290px;
  margin: 0 auto;
}
#ahsidebar-content div.sponsored_content_whatsthis {
  width: 300px;
  margin: 10px auto;
}
#ahsidebar-content div.sponsored_content_whatsthis a {
  font-style: italic;
  color: #888888;
}

.ahmeta_institute{
    margin: 2px 0 0 0;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    color: #909090;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.ahimage{
  background: #f1f1f1;
  border-radius: 24px;

}

.ahcenter-image{
  display: block;
  height:300px;
  margin-left: auto;
  margin-right: auto;
  max-width: -webkit-fill-available;
}

.ahwell{
  margin-top:25px;
  border-radius: 5px !important;
  background: #D3D3D3 !important;
}

.ahcenter-text{
   text-align: center;
}

.ahpage_title{
    font-weight: 300;
    font-size: 4em;
    color: #333333;
}
.ahsubtitle{

  font-size: 1em;
}.ahcredit{
  text-align: left;
  font-size: 1em;
    font-weight: 400;
    color: #777777;
}

.ahspacer{
  margin-bottom: 35px;
}

.ahSidebarSpacer{
    margin-bottom: 170px;
}
