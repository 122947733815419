/* Style for the PIO Tipsheet pages */

#content {
    font-size: 14px;
}

hr.divider {
    border: 0;
    height: 1px;
    background-color: #cccccc;
    margin: 35px 0px 35px 0px;
}

br.spacer {
    display: block;
    content: "";
    margin-top: 10px;
}

/* -------------------------------- */
/*  Page title
*/

h3.jama_title {
    margin: 30px 0px 30px 0px;
}

/* -------------------------------- */
/*  Links
*/
// TODO: @neetu need help finding better fix for this
// a {
//     color: #333333;
//     text-decoration: none;
// }

a.jama_highlighted_link {
    color: #d71534;
    text-decoration: underline;
}

// TODO: @neetu need help finding better fix for this
// a:hover {
//     color: #d71534;
//     text-decoration: underline;
// }

/* Remove hover underline effect from news release links in summaries list */

// TODO: @neetu need help finding better fix for this
// .post a:hover {
//     text-decoration: none;
// }
//
// .post a:focus {
//     text-decoration: none;
// }

// TODO: @neetu need help finding better fix for this
// a:visited {
//     color: #d71534;
// }

/* -------------------------------- */
/*  PIO -- Shared elements
*/

.jama_update_date {
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: 12px;
}

.jama_highlight_note {
    background-color: #edede3;
    margin: 25px 0px 25px 0px;
}

/* -------------------------------- */
/*  PIO -- Forthcoming list
*/

div.jama_forthcoming_article {
    margin: 35px 0px 30px 0px;
}

div.jama_journal_info {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

.jama_article_number {
    margin: 20px 0px 0px 0px;
}

.jama_embargo_time {
    color: #d71534;
}

h1.jama_forthcoming_article_title {
    font-size: 16px;
    margin: 8px 0px 20px 0px;
}

/* -------------------------------- */
/*  PIO -- Contributors List
*/

.jama_contributors_list br {
    display: block;
    content: "";
    margin-top: 10px;
}

.form-control .selectize-input {
    min-height: calc(1.5em + 0.75rem + 3px)!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}