/*

// Style for DOE Portal

*/


/* header & navigation */

h1.portal_title {
  color: #ffffff;
  text-shadow: 0px 1px 0px #404040;
/*  background: rgba(0, 0, 0, 0.6);
  display: inline-block;
  padding: 10px; */
}

h1.portal_title img {
  width: 320px;
  vertical-align: bottom;
  margin: 0px 25px 0px 0px;
}

nav.doe {
/*  background: #ffffff; */
  background-image: linear-gradient(#eeeeee, #ffffff, #eeeeee) !important;
  border-left: 1px solid #b9babc;
  border-right: 1px solid #b9babc;
  border-bottom: 1px solid #b9babc;
}
nav.doe .dropdown-toggle {
  color: #b9babc;
  background: #eeeeee;
}
nav.doe .dropdown-toggle:hover {
  color: #333333;
}
nav.doe .dropdown-toggle.collapsed {
  color: #000000;
  background-image: linear-gradient(#eeeeee, #ffffff, #eeeeee);
}
nav.doe .dropdown-toggle.collapsed:hover {
  color: #333333;
}

nav.doe #portal-nav ul li a {
	text-transform: uppercase;
    color: #333333;
}


/* main content */

.main {
    margin-top: 25px;
}

h1.pagehead {
  color: #003668;
  font-size: 17px;
  line-height: 17px;
  margin: 40px 0px 20px 0px;
}

h2.pagesubhead {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 25px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

h2.subhead {
  color: #1c1c1c;
  text-align: left;
  border-bottom: 1px solid #b9babc;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 3px;
}


/* For portal home page carousel */

.carousel.fade {
     opacity: 1;
}
.carousel.fade .item {
    transition: opacity ease-in-out 1s;
    left: 0;
    opacity: 0; /* hide all slides */
    top: 0;
    position: absolute;
    width: 100%;
    display: block;
}
.carousel.fade .item:first-child {
    top: auto;
    position: relative;
}
.carousel.fade .item.active {
    opacity: 1;
}

.carousel-indicators {
    position: absolute;
    bottom: -5px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
}

.carousel-indicators li {
    border-radius: 0;
}

.carousel-caption {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 8px 20px 30px 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-shadow: none;
    background: rgba(0, 0, 0, 0.6);
}


/* For large, static image, if we want to use that instead of a carousel */

.largetrendingphoto {
    height: auto !important;
}


/* article headline styles */

.trending {
  margin-bottom: 20px;
}

.featured.trending header {
  margin-top: 0;
  padding: 10px;
  border: 1px solid #b9babc;
}

a.view-all {
  margin-top: 0px;
  margin-bottom: 20px;
}

.post {
  margin-bottom: 20px;
  border-bottom: none;
}

.post a header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.feature-card {
  padding-left: 10px;
  padding-right: 10px;
  height: 380px;
}

.card {
  width: 100%;
  padding: 0px 10px 15px 10px;
}

.card img.card-img-top {
  max-width: 100%;
  max-height: 100%;
}

.thumb-img-box-outer {
  width: 80px;
  border: 1px solid #b9babc;
  padding: 3px;
  margin-bottom: 10px;
}

.thumb-img-box {
     position: relative;
     width: 100%;
     padding-bottom: 100%;
}

.thumb-img-box-content {
    position:absolute;
    width:100%;
    height:100%;
    text-align:center;
}

.thumb-img-box-content img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-height:100%;
    max-width:100%;
}

.card-img-box-outer {
  border: 1px solid #b9babc;
  padding: 3px;
  margin-bottom: 10px;
}

.card-img-box {
     position: relative;
     width: 100%;
     padding-bottom: 75%;
}

.card-img-box-content {
    position:absolute;
    width:100%;
    height:100%;
    text-align:center;
}

.card-img-box-content img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-height:100%;
    max-width:100%;
}

.card p.card-text {
  font-size: 18px;
}

.card p.card-text a {
  color: #1c1c1c;
}


.article h1.title {
    color: #2b2b2b;
    margin: 25px 0 0;
    font-weight: 300;
    letter-spacing: -0.34px;
}


.article h2.subtitle {
    margin: 10px 0px 15px 0px;
    font-size: 17px;
    font-style: italic;
    font-weight: 300;
    color: #7b7b7b;
}


.headline header h2 {
    font-size: 14px;
}

.headline header .meta_institute {
    font-size: 10px;
}

#sidebar-content .widget h3.widget-title.doe {
  background: #e0f9b8;
  border-color: #7cc110;
}

#sidebar-content .twitter-feed {
  height: 446px;
}

.topic-links li {
    list-style-type: none;
    padding: 5px 0;
    margin: 0px 0px 10px -35px;
}

.topic-links li a {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.topic-links li a.active {
    font-weight: 700;
}

img.doe_topic_icon {
  margin: -2px 8px 0px 0px;
}

div.doe_image_frame {
  width: 220px;
  padding: 8px;
  text-align: center;
  border: 1px solid #b9babc;
  border-radius: 3px;
}

.doe_image_left {
  margin: 20px auto;
}

.doe_image_right {
  margin: 20px auto;
}


img.doe_image {
  max-width: 200px;
  max-height: 200px;
}

div.doe_image_caption {
  font-size: 11px;
  text-align: left;
  margin-top: 5px;
  padding: 8px;
}


/* For "legacy" images embedded in DOE features, which are contained in a TABLE */

.legacy_doe_image {
  max-width: 280px;
  margin: 20px auto;
  border: 1px solid #b9babc;
  border-collapse: separate;
}

.legacy_doe_image td {
  padding: 10px;
}

.legacy_doe_image td hr {
  display: none;
}

.legacy_doe_image td img {
  max-width: 240px;
  max-height: 240px;
  margin-bottom: 10px;
}

.legacy_doe_image td .imagecaption {
  font-size: 11px;
}


/* For "legacy" images embedded in DOE features, which are standalone IMG embeds */

img.legacy_doe_solo_image {
  max-width: 90%;
  margin: 0 auto;
}

.lab-card {
  height: 120px;
  padding: 10px 5px;
  margin: 0px 0px 30px 0px;
}

.lab-card:hover {
  background-color: #eaeaea;
}

.lab-logo-image {
  margin-right: 10px;
  border: 1px solid #888888;
}

.lab-name {
  height: 78px;
  padding-top: 2px;
}

.lab-content-links {
  margin-bottom: 2px;
}

.lab-content-links a {
  color: #888888;
  text-transform: uppercase;
}

.labs-intro {
  margin: 0px 0px 30px 0px;
}

#labsmap {
  width: 250px;
  max-width: 50%;
  margin-left: 15px;
}

.lab-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  margin: 0px 0px 5px 0px;
}

.lab-title-location {
  font-size: 14px;
  font-style: italic;
  margin: 0px 0px 20px 0px;
}

.lab-description {
  margin: 0px 0px 30px 0px;
}

.topics-intro {
  margin: 0px 0px 30px 0px;
}

.topic-card {
  font-size: 16px;
  font-weight: 600;
  padding: 25px;
}

a.topic-card-link {
  color: #000000;
}

.topic-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 20px 0px;
}

.topic-description {
  margin: 0px 0px 30px 0px;
}

.multimedia-item a {
  border: 1px solid #b9babc;
}

@media (min-width: 576px) {

  .doe_image_left {
    margin: 10px 20px 10px 5px;
    float: left;
  }

  .doe_image_right {
    margin: 10px 5px 10px 20px;
    float: right;
  }

  .legacy_doe_image {
    width: 160px;
  }

  .legacy_doe_image_left {
    margin: 10px 20px 10px 5px;
    float: left;
  }

  .legacy_doe_image_right {
    margin: 10px 5px 10px 20px;
    float: right;
  }

  .legacy_doe_image td img {
    max-width: 140px;
    max-height: 140px;
  }

}

@media (max-width: 767px) {

  nav.doe {
      background: #ffffff;
  }

  nav.doe #portal-nav ul li {
      border-bottom: 1px solid #b9babc;
  }

  nav.doe #portal-nav ul li a {
      color: #000000;
  }

}

@media (min-width: 768px) {

  nav.doe #portal-nav ul li {
      border-left: 1px solid #b9babc;
  }

  nav.doe #portal-nav ul li:first-child {
      border-left: none;
  }

  #portal-nav ul li.active:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #b9babc !important;
  }

  .feature-card {
    height: 340px;
  }

}

@media (min-width: 992px) {

  #main-content{
      background-color: #ffffff;
      border-left: 1px solid #b9babc;
  }

  h2.post_title {
    font-size: 14px;
  }

  .post {
    margin-bottom: 15px;
  }

  .post a header span {
      font-size: 10px;
  }

  .post a header span {
    margin: 2px 0 0 0;
    font-size: 10px;
    font-weight: 400;
    color: #909090;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .card p.card-text {
    font-size: 14px;
  }

  .feature-card {
    height: 280px;
  }

  #sidebar-content {
      padding-top: 15px;
  }

}
