.account-nav {
  &.nav-pills>li.active>a,
  &.nav-pills>li.active>a:hover,
  &.nav-pills>li.active>a:focus {
    background-color: #6d757d;
  }

  a {
    color: #6d757d;
  }
}

.account-heading-box {
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  margin-bottom: 10px;
}

.pio-my-releases {
  border-radius: 4px;
  border: 1px solid #cccccc;

  &__release {
    border-bottom: 1px solid #cccccc;

    &:last-child {
      border-bottom: none;
    }
  }
  td {
    padding: 5px;
  }
  .label {
    display: block;
  }
  td.pio-my-releases__title {
    padding-left: 15px;
  }
  td.pio-my-releases__actions {
    padding-right: 15px;
  }
}
