/* -------------------------------------------------------

   Style sheet for EurekAlert! content that will be carried over
   in the 2013 redesign.

   ------------------------------------------------------- */

.basicstyle {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

.basicstyle table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}

.basicstyle td, th {
  display: table-cell;
  vertical-align: inherit;
}

.basicstyle hr {
  display: block;
  border-style: inset;
  border-width: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-right: 0px none;
  border-width: 1px 0px 0px;
  border-color: #eeeeee;
  border-image: none;
  height: 1px;
}

.basicstyle input, button, select, textarea {
  margin: 3px 0px 3px 0px;
  border: 1px solid #bbbbbb;
}

.basicstyle a {
  color: #2c56ac;
  text-decoration: none;
}

.basicstyle a:hover {
  color: #2c56ac;
  text-decoration: none;
}

.basicstyle .helpicon {
  font-size: 24px;
  color: #cb2121;
}

.basicstyle dt {
  font-weight: bold;
}

.basicstyle dd {
  font-weight: normal;
  padding-left: 24px;
}

.basicstyle label {
  font-weight: normal;
}

hr.thin {
  border: 0;
  height: 1px;
  background-color: #505050;
}

b.boldlite {
  color: #4c4c4c;
}

h1.pagehead {
  color: #DC0002;
  display: block;
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 13.75px;
  margin-bottom: 8px;
  margin-top: 25px;
  margin-left: 2px;
  text-transform: uppercase;
}

h1.pageheadsolo {
  color: #dc0002;
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 34px;
  font-weight: 300;
  letter-spacing: -0.333333px;
  line-height: 37.4px;
  margin-bottom: 25px;
}

h2.pagesubhead {
  color: #555555;
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 34px;
  font-weight: 300;
  letter-spacing: -0.333333px;
  line-height: 37.4px;
  margin-bottom: 25px;
}

h4.sectionhead {
  color: #cb2121;
  margin-top: 40px;
  margin-bottom: 18px;
}

a.blacklink {
	color: #222222;
	text-decoration: none;
}
a.blacklink:hover { color: #2c56ac; text-decoration: none; }
a.blacklink:visited { color: #222222; text-decoration: none; }

p.journal_link {
  font-size: 15px;
  margin-bottom: 20px;
}

a.redlink {
    color: #cb2121;
	text-decoration : none;
}
a.redlink:hover { color: #cb2121; text-decoration: underline; }
a.redlink:visited { color: #cb2121; text-decoration: none; }

a.whitelink {
    color: #000000;
	text-decoration : none;
}
a.whitelink:hover { color: #000000; text-decoration: underline; }
a.whitelink:visited { color: #000000; text-decoration: none; }

div.printlink {
  float: right;
}

div.error {
  margin: 1em 0em 1em 0em;
  padding: .3em .3em .3em .3em;
  background-color: #eeeeee;
  border: .1em black solid;
}

.loginbox {
  border: 1px black solid;
  margin: 10px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
}

.mediakittableheader {
  font-size: 14px;
}

strong.required {
  color: #cb2121;
}

a.userhomelink {
  color: #ffdb3d !important;
}

/* Style for reporter and PIO home pages */

div.userhomeleft {
  width: 50%;
  padding: 0px 20px 10px 0px;
  float: left;
  border-right: 1px dashed gray;
  margin: 10px 0px 10px 0px;
}

div.userhomeright {
  width: 50%;
  padding: 0px 10px 10px 40px;
  float: right;
  margin: 10px 0px 10px 0px;
}

/* For "Embargoed News & Journals" link on reporter page, and others */
div.usertopbutton {
  background-color: #ffdb3d;
  padding: 5px 10px 5px 10px;
  color: black;
  font-weight: bold;
  width: 250px;
  margin: 0px 0px 10px 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* For "Submit to EurekAlert!" link on PIO page, and others */
div.usertopbuttonsubmittop {
  background-color: #ffdb3d;
  padding: 5px 10px 5px 10px;
  color: black;
  font-weight: bold;
  width: 250px;
  margin: 0px 0px 0px 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div.usertopbuttonsubmitbottom {
  background-color: gray;
  padding: 5px 10px 5px 10px;
  font-size: 11px;
  color: white;
  font-weight: bold;
  width: 250px;
  margin: 0px 0px 10px 0px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  clear: both;
}

div.usertopbuttonarrows {
  color: #cb2121;
  float: right;
}

a.usertopbuttonlink {
  text-decoration: none;
}

a.usertopbuttonlink:hover {
  text-decoration: none;
}

a.usertopbuttonlink:active {
  text-decoration: none;
}

a.usertopbuttonlink:visited {
  text-decoration: none;
}

div.userhomeseparator {
  margin: 35px 0px 30px 0px;
}

div.userhomeseparatorall {
  margin: 35px 0px 30px 0px;
  border-top: 1px dashed gray;
}

/* Make JW Player Responsive */
/* For video featured on individual MM pages
   and as "LARGE" videos on news releases      */

.JWplayerForMMitem {
  width: 100%;
  max-width: 640px;
}

/* For video featured on reporter & PIO landing pages */
.JWplayerForLanding {
  float: right;
  width: 356px;
  margin-left: 1em;
  margin-right: 1em;
}


/* Logos in Science Sources */

.scisrclogo {
  margin: 25px 0px 25px 0px;
  float: right;
}


/* For right sidebar menus */

.rightlist {
  margin: 15px 0px 15px 0px;
  width: 100%;
}

.rightlistheader {
  background-color: darkgrey;
  text-align: left;
  height: 25px;
}

.rightlisttitle {
  color: white;
  font-size: 16px;
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 1px 10px 1px 10px;
  padding-top: 3px;
}

.rightlistbody {

}

.rightlistitem {
  padding: 7px 7px 7px 7px;
}

.rightlistlinkbold {
  color: #000000;
  font-weight: bold;
}

.rightlistinstname {
  color: #909090;
  text-transform: uppercase;
}

.rightlisttimeicon {
  display: inline-block;
  float: right;
  color: #909090;
}

.rightlistlinkbold:visited {
  color: #000000;
}

.rightlistdivider {
  background-color: #cccccc;
  height: 1px;
  width: 100%;
}

.rightlisttable {
  border: none;
  width: 90%;
}

td.NR_NavigationRule hr {
  margin: 3px 0px 3px 0px !important;
}

.rightlistcontent {
  width: 90%;
  margin: 10px auto 10px auto;
}

/* For positioning ads in adbars */

.buttonadbar {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0px auto 0px auto;
  border: 1px darkgray solid;
  background-color: white;
}

.buttonadbarsection {
  margin: 5px auto 5px auto;
}


/* To make long URLs wrap in releases instead of breaking out of their container */

.release a.url_wrap {
  word-wrap: break-word;
  -ms-word-wrap: break-word;
}

/* General class to make long URLs wrap  */

.wraplongurls {
  word-wrap: break-word;
  -ms-word-wrap: break-word;
/*white-space: pre;
  word-wrap: break-word;
  overflow-wrap: break-word; */
}

wbr:after {
  content: "\00200B";
}


/* For release dates in news releases */

.reldatetext {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
}

.reldatedate {
  margin-top: 10px;
  display: inline-block;
}

.reldatetextemb {
  float: left;
  margin-right: .5em;
  display: inline-block;
  color: #cc0000;
}

.reldatedateemb {
  display: inline-block;
}


/* h1.page_title style - developed by Nick, but removed because it was messing up Google search results */
h1.page_title small {
  display: block;
  text-transform: uppercase;
  color: #333333;
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: normal;
  margin-bottom: 10px;
}
h1.page_title small.red {
  color: #dc0002;
}
h1.page_title small .lite {
  color: #333333;
  font-weight: 400;
}


.featured_image_large {
  margin: 10px 0px 0px 0px;
  clear: both;
  width: 100%;
}

.featured_image_large img {
  max-width: 100%;
}

.featured_image_large .caption {
    font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
    margin: 6px 0px 3px 0px;
}

.featured_image_large .credit {
    color: gray;
    font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
    margin: 3px 0px 0px 0px;
}

.featured_image_large .video {
    max-width: 640px;
}

.featured_image_embed {
  margin: 15px 0px 15px 15px;
  float: right;
  width: 300px;
  max-width: 100%;
}

.featured_image_embed img {
  max-width: 288px;
  max-height: 288px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.featured_image_embed .imagecontainer {
  border: 1px solid gray;
  height: 300px;
  width: 300px;
  position: relative;
}

.featured_image_embed .caption {
    font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
    margin: 6px 0px 3px 0px;
}

.featured_image_embed .credit {
    color: gray;
    font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
    margin: 3px 0px 0px 0px;
}



/* For older images that are embedded in release text */

.legacyimage {
  margin: 0px 0px 10px 10px;
  max-width: 250px;
  float: right;
}

.legacyimage img {
  max-width: 250px;
}


/* For reporter-only text box on news releases */

div.mediaonly {
  width: 100%;
  border: 1px solid gray;
  padding: 10px 10px 10px 10px;
}

/* Make Font-Awesome icons red */

i.fa-eurekalert-red {
  color: #cc0000;
}

/* style for the addthis icons */
div .addthiscontainer {
  width: 100%;
  font-size: 24px;
}

div .addthiscontainer a {
  color: gray;
}

div .addthiscontainer .addthisleft {
  float: left;
}

div .addthiscontainer .addthisright {
  float: right;
}


/* For summaries pages */

a.summarytoggle {
  color: #888888;
}

a.summarytoggle:hover {
  color: #888888;
}

p.nosummaries {
  font-size: 16px;
  font-style: italic;
  padding: 35px 0px 20px 0px;
}

/* For Multimedia search form */

table.query {
  border: 1px solid gray;
}
.help-text{
    color: gray;
    font-size: 13px;
}
/* Styles for individual multimedia pages */

img.featuredimage {
  border: none;
  max-width: 100%;
  max-height: 600px;
}


/* For PIO audit reports */

.audittableheader {
  color:white;
  font-size: 12px;
  font-weight: bold;
}


/* For PIO submission forms */

a.redlink_submit {
	font-size: 14px;
	font-weight: bold;
	color: #cb2121;
	text-decoration: none;
}

a.redlink_submit:hover { color: #cb2121; text-decoration: underline; }
a.redlink_submit:visited { color: #cb2121; text-decoration: none; }

a.bluelink_submit {
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
}

a.bluelink_submit:hover { text-decoration: underline; }
a.bluelink_submit:visited { text-decoration: none; }



  /* For the home page welcome banner */
  /* REMOVE SOMETIME IN JANUARY 2015 */

  #welcomebanner {
    z-index: -10;
  }

  #welcomebox {
    background-color: #fcfcfc;
	height: 130px;
	margin: 25px 0px 10px 0px;
	padding: 10px 10px 10px 10px;
	border: 1px solid #505050;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
  }

  #welcomecloselink {
    float: right;
	font-size: 16px;
	margin-right: -3px;
	margin-top: -10px;
  }

  #welcomeimagecontainer {
    float: right;
	margin-right: 70px;
  }

  #welcomeimage {
   height: 100px;
  }

  #welcomemessagecontainer {
	 margin-left: 5px;
  }

  #welcomemessage {
   font-size: 15px;
   color: #000000;
   margin: 0px 0px 10px 0px;
  }

  #welcomeblurb {
   font-size: 14px;
   color: #505050;
   margin: 0px 0px 10px 0px;
  }

  #welcomelink {
   font-size: 13px;
   margin: 0px 0px 10px 0px;
  }

  /* For the "about our new design" page */
  /* REMOVE SOMETIME IN JANUARY 2015 */

#newdesignthumb {
  float: right;
  width: 150px;
  margin: 0px 15px 20px 20px;
}

h3.newdesign {
  margin-bottom: 18px;
}

li.newdesign {
  font-size: 15px;
  margin-top: 15px;
}

#feedbackbox {
  color: #222222;
  font-size: 15px;
  margin-top: 40px;
  padding: 25px 25px 25px 25px;
  border: 1px solid gray;
  background-color: white;
}


/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */

/* MEDIA QUERIES */


/* ------------------------------------------------------- */
/*  BEGIN Desktop view (992px or more)                     */

@media screen and (min-width: 992px) {

  a.nomobile {
    display: inline;
  }

  span.nomobile {
    display: inline;
  }

  p.nomobile {
    display: block;
  }

  div.nomobile {
    display: block;
  }

  a.mobileonly {
    display: none;
  }

  span.mobileonly {
    display: none;
  }

  p.mobileonly {
    display: none;
  }

  div.mobileonly {
    display: none;
  }

  div.nodesktop {
    display: none;
  }

  div.desktoponly {
    display: block;
  }

  /* For the Trending News page (/trending) */

  a.trendingpageheadline {
    min-height: 110px;
  }


  #welcomemessagecontainer {
	 margin: 12px 0px 0px 100px;
  }

  #welcomeimagecontainer {
    float: right;
	margin-right: 140px;
  }

}

/* END Desktop view */
/* ------------------------------------------------------- */


/* ------------------------------------------------------- */
/* BEGIN Tablet view (768px -> 991px) */

@media screen and (min-width: 768px) and (max-width: 991px) {

  div.nodesktop {
    display: block;
  }

  div.desktoponly {
    display: none;
  }

  /* For the Trending News page (/trending) */

  a.trendingpageheadline {
    min-height: 170px;
  }

  #welcomemessagecontainer {
	 margin: 10px 0px 0px 55px;
  }

  #welcomeimagecontainer {
    float: right;
	margin-right: 55px;
  }

}
/* END tablet view (768px -> 991px) */
/* ------------------------------------------------------- */


/* ------------------------------------------------------- */
/* BEGIN mobile view (767px or less) */

@media screen and (max-width: 767px) {

  a.nomobile {
    display: none;
  }

  span.nomobile {
    display: none;
  }

  p.nomobile {
    display: none;
  }

  div.nomobile {
    display: none;
  }

  a.mobileonly {
    display: inline;
  }

  span.mobileonly {
    display: inline;
  }

  p.mobileonly {
    display: block;
  }

  div.mobileonly {
    display: block;
  }

  div.nodesktop {
    display: block;
  }

  div.desktoponly {
    display: none;
  }

  div.userhomeleft {
    width: 100%;
    padding: 0px 10px 10px 10px;
    float: none;
	border-right: none;
	border-bottom: 1px dashed gray;
	margin-bottom: 25px;
  }

  div.userhomeright {
    width: 100%;
    padding: 0px 10px 10px 10px;
    float: none;
	border-bottom: 1px dashed gray;
  }

  div.userhomeseparator {
    border-top: 1px dashed gray;
  }

  /* For video featured on reporter & PIO landing pages */
  .JWplayerForLanding {
    float: none;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
	border: 1px solid black;
  }

  /* Logos in Science Sources */

  .scisrclogo {
    float: none;
	width: 25%;
  }

  .scisrclogoimage {
    width: 25%;
  }

  select.widelist {
    width: 95%;
  }


  .reldatetext {
    float: none;
	display: block;
  }

  .reldatedate {
	display: block;
  }


  /* For the Trending News page (/trending) */

  a.trendingpageheadline {
    min-height: 0px;
  }

/*
  h1.pagehead {
    margin-left: -5px;
  }

  h1.pageheadsolo {
    margin-left: -5px;
  }
*/
  h2.pagesubhead {
    margin-left: -2px;
  }

  #welcomeblurb {
   min-height: 40px;
  }

  #welcomemessagecontainer {
    width: 250px;
    margin: 0 auto;
  }

#newdesignthumb {
  width: 120px;
  margin: 0px 5px 10px 10px;
}

}


/*Things out bootstrap doesnt do*/

.btn-outline-success{
  color: #28a745;
background-color: transparent;
background-image: none;
border-color: #28a745;
border: 1px solid #28a745 !important;
}
.btn-outline-success:hover{
color: #ffffff !important;
background-color: #28a745;
border-color: #28a745;
}
.btn-outline-success.focus, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(40,167,69,.5);
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:focus, .btn:hover {
    text-decoration: none;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #999;
}


/* END 767px or less */
/* ------------------------------------------------------- */


/* ------------------------------------------------------- */
/* BEGIN 550px breakpoint for release images */

@media screen and (max-width: 550px) {

  .featured_image_embed {
    margin: 15px auto 15px auto;
    float: none;
  }

  .legacyimage {
    margin: 10px auto 10px auto;
    float: none;
  }


}
/* END 550px breakpoint for release images */
/* ------------------------------------------------------- */



/* END MEDIA QUERIES */
/* ------------------------------------------------------- */


/* -------------------------------------------------------

   Maybe don't carry this stuff over!

   ------------------------------------------------------- */

.body {
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
}

.releasetitle {
  font-size: 30px;
  font-weight: 400;
}

.releasesubtitle {
  font-style: italic;
  font-size: 21px;
  font-weight: 300;
}

.relemb {
  font-size: 13px;
  font-weight: 400;
}

.piosurvey {
	width:400px;
	margin:0 auto;
	border: 2px solid black;
	padding: 5px;
	text-align: center;
}
