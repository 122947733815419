// bootstrap doesn't offer vertical spacing classes
.margin-top-xs { margin-top:5px; }
.margin-top-sm { margin-top:10px; }
.margin-top-md { margin-top:20px; }
.margin-top-lg { margin-top:30px; }
.margin-top-xl { margin-top:50px; }
.margin-right-xs { margin-right:5px; }
.margin-right-sm { margin-right:10px; }
.margin-right-md { margin-right:20px; }
.margin-right-lg { margin-right:30px; }
.margin-right-xl { margin-right:50px; }
.margin-bottom-xs { margin-bottom:5px; }
.margin-bottom-sm { margin-bottom:10px; }
.margin-bottom-md { margin-bottom:20px; }
.margin-bottom-lg { margin-bottom:30px; }
.margin-bottom-xl { margin-bottom:50px; }

// add padding classes
.padding-xs { padding: .25em; }
.padding-sm { padding: .5em; }
.padding-md { padding: 1em; }
.padding-lg { padding: 1.5em; }
.padding-xl { padding: 3em; }

.card-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 12px;
    border: 1px solid #8c8c8c;
    border-radius: 8px;
    flex: 1 100%;

    @media (min-width: 400px) {
      flex: 0 0 48%;
      margin-right: 2%;
    }

    @media (min-width: 500px) {
      flex: 0 0 31%;
      margin-right: 3%;
	  
	  &:nth-child(3n) {
        margin-right: 0;
      }
    }

    a {
      color: #000000;
    }
  }

  &.card-grid--stacked { // sidebar version
    .card-grid__item { // make cards full width
      flex: 1;
      margin-right: 0;
    }
    .microsite-card__heading { // remove some unneeded spacing
      margin-bottom: 0;
    }
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */
   .microsite-card {
     &__inner {
       display: block !important; 
       flex-direction: column;
       padding: 8px 8px 16px;
       text-align: center;
     }

     &__image {
       margin-bottom: 10px;

       img {
         max-width: 100%;
         margin: 0 auto;
         display: block;
       }
     }

     &__heading {
       margin-bottom: 6px;
     }

     &__title {
       font-weight: bold;
       font-size: 16px;
     }

     &__tagline {
       font-size: 14px;
     }

     &__meta {
       font-size: 13px;
     }

     &__description {
       margin-top: 8px;
       font-size: 11px;
       color: #333333;
     }
   }
}
.microsite-card {
  &__inner {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 16px;
    text-align: center;
  }

  &__image {
    margin-bottom: 10px;

    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  &__heading {
    margin-bottom: 6px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
  }

  &__tagline {
    font-size: 14px;
  }

  &__meta {
    font-size: 13px;
  }

  &__description {
    margin-top: 8px;
    font-size: 11px;
    color: #333333;
  }
}

.multimedia-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__grid-item {
    width: 48%;
    position: relative;

    &:hover,
    &:focus {
      cursor: pointer;

      .multimedia-grid__title {
        display: block;
      }
    }
  }
  &__image,
  img {
    max-width: 100%;
    height: auto;
  }
  &__title {
    display: none;

    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 20px;
    width: 100%;
    background: rgba(0, 0, 0, 0.57);
    color: #ffffff;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
  }
}
.ea-keyword {
  &__path {
    display: none;
  }

  &--reveal {
    .ea-keyword__path {
      display: block;
    }
  }
}
.press-release-datepicker, .embargoednews-datepicker {
  width: 200px;
}

.press-release-card__meta {
  display: flex;
  align-items: center;
}

.press-release-card__meta .reltime {
  margin-right: 10px;
}

.press-release__multimedia-icons {
  margin-right: 10px;
}

.new-label {
  color: #28a744;
  text-transform: uppercase;
  font-weight: bold;
}

// use flexbox to wrap contact search topics into two columns on desktop
#media_contact_search_topic {
    @media (min-width: 550px) {
      display: flex;
      flex-direction: column;
      max-height: 150px;
      flex-wrap: wrap;
    }
}
